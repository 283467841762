import { FC } from 'react';
import { Input, InputProps } from './Input';
import SearchIcon from '../icon/SearchIcon';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import XIcon from '../icon/XIcon';

type SearchInputProps = InputProps;

export const SearchInput: FC<SearchInputProps> = (props) => {
  return (
    <div className="relative w-full" data-cy="search-input">
      <Input
        {...dataAttributeProps(props)}
        {...ariaAttributeProps(props)}
        {...props}
        type="search"
        autocomplete="new-password"
        data-testid="search-field"
      >
        <Input.Slot name="leading">
          <SearchIcon className="mr-2 h-6 w-6" />
        </Input.Slot>
        <Input.Slot name="trailing">
          {props.value && (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <XIcon className="mr-2 h-4 w-4" onClick={() => props.onChange && props.onChange({ target: { value: '' } } as any)} />
          )}
        </Input.Slot>
      </Input>
    </div>
  );
};
